// Page Template
body#designtemplate_list {
	fieldset table {
		display: block;
		tbody {
			tr:first-child {
				display: none;
			}
			display: block;
		}
		.btn_edit {
			right: 375px;
			background: transparent !important;
		}
		.btn_delete {
			right: 325px;
		}
		.btn_preview {
			right: 274px;
		}
		.btn_raw {
			right: 222px;
		}
		tr {
			position: relative;
			display: block;
			border-top: 1px solid rgba(0, 0, 0, 0.19);
			border-right: 1px solid rgba(0, 0, 0, 0.23);
			border-left: 1px solid rgba(0, 0, 0, 0.23);
			width: 48%;
			margin-right: 5px;
			box-sizing: border-box;
			float: left;
			padding: 3px 5px;
			margin-bottom: 0;
			&:hover {
				background: #FFF99B !important;
			}
			a {
				padding: 6px !important;
				margin: 5px 0 !important;
				font-size: 12px !important;
				line-height: 1.4em !important;
			} // Modifier
			a[href^="designtemplate_edit.php?TemplateID"] {
				background: #009688;
				color: #FFFFFF !important;
			} // Delete
			a[href^="/app/designtemplate_list.php?action=deleteTemplate"] {
				background: #E91E36;
				color: #FFFFFF !important;
			} // Preview
			a[href^="designtemplate_preview.php?action=preview&TemplateID"] {
				background: #2196F3;
				color: #FFFFFF !important;
			} // Preview Raw
			a[href^="designtemplate_preview.php?action=tagMap&TemplateID"] {
				background: #FFC107;
				color: #FFFFFF !important;
			}
			.btns {
				position: absolute;
				z-index: 4;
				top: -16px;
				zoom: 0.8;
				transition: all $uitime;
				a {
					color: white !important;
					box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.31) !important;
					&:hover {
						box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.51) !important;
					}
					&:active {
						box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.71) !important;
					}
				}
			}
		}
	}
}

// Edition du code tag et page template
#designtemplate_edit, #tag_edit, #sessiontag_edit {
	a[href="designtemplate_edit.php"], input[type="submit"] {
		display: block !important;
		position: fixed;
		bottom: 50px;
		right: 50px;
		background: $new_add_button_color !important;
		color: #FFFFFF !important;
		z-index: 15;
		&:hover {
			background: darken($new_add_button_color, 10%) !important;
		}
		&:active {
			background: darken($new_add_button_color, 15%) !important;
		}
	}
	input[type="submit"] {
		background-image: url(save.png) !important;
		background-size: 40% 40% !important;
		background-repeat: no-repeat !important;
		background-position: 50% !important;
		border-radius: 50%;
		min-width: 60px !important;
		min-height: 60px !important;
		&:hover, &:active {
			background-image: url(save.png) !important;
			background-size: 40% 40% !important;
			background-repeat: no-repeat !important;
			background-position: 50% !important;
			border-radius: 50%;
			min-width: 60px !important;
			min-height: 60px !important;
		}
	}
}

// Liste de tags
#tag_list fieldset p table, #sessiontag_list fieldset p table {
	tbody tr:nth-child(1) {
		// display: none;
	}
	tbody tr {
		border: 1px solid rgb(255, 255, 255);
		width: 100%;
		padding: 4px 11px !important;
		margin: 0;
		position: relative;
		border-top: 0;
		box-sizing: border-box;
		display: block;
		transition: all $uitime;
		&:hover {
			background: rgba(255, 236, 68, 0.6) !important;
		}
		&:active {
			background: rgba(255, 236, 68, 0.8) !important;
		}
		td:nth-child(1) {
			font-size: 14px;
			color: #595959;
			display: inline-block !important;
			text-align: left;
			font-weight: 700;
			margin-right: 23px;
			min-width: 320px;
		}
		td:nth-child(2) {
			font-size: 14px;
			color: #595959 !important;
			display: inline-block;
			text-align: left;
			font-weight: 400;
		}
		td:nth-child(3) {
			z-index: 12;
			position: absolute !important;
			display: inline-block;
			top: -10px;
			right: 141px;
			background: transparent !important;
		}
		a[href^="tag_edit"] {
			padding: 0;
			display: inline-block;
			background: #009688 !important;
			color: white !important;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 400;
			width: 50px;
			height: 50px;
			transition: all $uitime;
			padding: 12px 0;
			box-sizing: border-box;
			border-radius: 50%;
			z-index: 9;
			&:hover {
				background: darken(#009688, 10%) !important;
			}
			&:active {
				background: darken(#009688, 18%) !important;
			}
		}
		td:nth-child(4) {
			position: absolute !important;
			display: inline-block;
			top: -10px;
			right: 80px;
			z-index: 9;
		}
		a[onclick^="return DeleteTag"] {
			padding: 0;
			display: inline-block;
			background: #E91E36 !important;
			color: white !important;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 400;
			width: 50px;
			height: 50px;
			transition: all $uitime;
			border-radius: 50%;
			padding: 12px 0;
			box-sizing: border-box;
			z-index: 9;
			&:hover {
				background: darken(#E91E36, 10%) !important;
			}
			&:active {
				background: darken(#E91E36, 18%) !important;
			}
		}
		td:nth-child(5) {
			position: absolute !important;
			display: inline-block;
			top: -10px;
			right: 20px;
			z-index: 9;
			form {
				margin: 0 !important;
			}
		}
		a[href^="tag_refactor"] {
			background: #2196F3 !important;
			color: white !important;
			display: block;
			width: 50px;
			height: 50px;
			transition: all $uitime;
			padding: 12px 0;
			box-sizing: border-box;
			z-index: 9;
			border-radius: 50%;
			&:hover {
				background: darken(#2196F3, 10%) !important;
			}
			&:active {
				background: darken(#2196F3, 18%) !important;
			}
		}
		td:nth-child(6) {
			font-family: $codeFont;
			color: #795548;
			font-size: 14px;
			background: #FFF8E3 !important;
			position: absolute !important;
			right: 222px;
			top: 23px !important;
			padding: 10px;
			min-height: 36px;
			border-radius: 3px;
			box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.43);
			width: 480px;
			z-index: 9;
		}
	}
	tbody tr:not(.hide):nth-child(even) {
		background: #E6E6E6;
	}
}
