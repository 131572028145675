@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700|Source+Code+Pro:500,600,900&subset=latin-ext');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');



// B CODE FONTS
@font-face {
	font-family: 'OperatorMono-Medium';
	src: url('OperatorMono-Medium.eot?#iefix') format('embedded-opentype'),  url('OperatorMono-Medium.otf')  format('opentype'),
	url('OperatorMono-Medium.woff') format('woff'), url('OperatorMono-Medium.ttf')  format('truetype'), url('OperatorMono-Medium.svg#OperatorMono-Medium') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'OperatorMono-MediumItalic';
	src: url('OperatorMono-MediumItalic.eot?#iefix') format('embedded-opentype'),  url('OperatorMono-MediumItalic.otf')  format('opentype'),
	url('OperatorMono-MediumItalic.woff') format('woff'), url('OperatorMono-MediumItalic.ttf')  format('truetype'), url('OperatorMono-MediumItalic.svg#OperatorMono-MediumItalic') format('svg');
	font-weight: normal;
	font-style: normal;
}
// E CODE FONTS







// B MATERIAL ICONS
// @font-face {
// 	font-family: 'Material Icons';
// 	font-style: normal;
// 	font-weight: 400;
// 	src: url('MaterialIcons-Regular.eot'); /* For IE6-8 */
// 	src: local('Material Icons'),
// 	local('MaterialIcons-Regular'),
// 	url('MaterialIcons-Regular.woff2') format('woff2'),
// 	url('MaterialIcons-Regular.woff') format('woff'),
// 	url('MaterialIcons-Regular.ttf') format('truetype');
// }


.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}
// E MATERIAL ICONS