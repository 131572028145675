/* BASICS */

.CodeMirror {
	/* Set height, width, borders, and global font properties here */
	font-family: $codeFont, monospace !important;
	height: auto;
	color: black;
	font-size: $codeFontSize;
}


.CodeMirror-lines {
	padding: 2px 0; /* Vertical padding around content */
}
.CodeMirror pre {
	padding: 2px; /* Horizontal padding of content */

}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
	background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-gutters {
	border-right: 1px solid #ddd;
	background-color: #112b3e;
	white-space: nowrap;
}
.CodeMirror-linenumbers {}
.CodeMirror-linenumber {
	padding: 0 3px 0 5px;
	min-width: 20px;
	text-align: right;
	color: #999;
	white-space: nowrap;
}

.CodeMirror-guttermarker { color: black; }
.CodeMirror-guttermarker-subtle { color: #999; }

/* CURSOR */

.CodeMirror-cursor {
	border-left: 1px solid black;
	border-right: none;
	width: 0;
}
/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
	border-left: 1px solid silver;
}
.cm-fat-cursor .CodeMirror-cursor {
	width: auto;
	border: 0;
	background: #7e7;
}
.cm-fat-cursor div.CodeMirror-cursors {
	z-index: 1;
}

.cm-animate-fat-cursor {
	width: auto;
	border: 0;
	-webkit-animation: blink 1.06s steps(1) infinite;
	-moz-animation: blink 1.06s steps(1) infinite;
	animation: blink 1.06s steps(1) infinite;
	background-color: #7e7;
}
@-moz-keyframes blink {
	0% {}
	50% { background-color: transparent; }
	100% {}
}
@-webkit-keyframes blink {
	0% {}
	50% { background-color: transparent; }
	100% {}
}
@keyframes blink {
	0% {}
	50% { background-color: transparent; }
	100% {}
}

/* Can style cursor different in overwrite (non-insert) mode */
.CodeMirror-overwrite .CodeMirror-cursor {}

.cm-tab { display: inline-block; text-decoration: inherit; }

.CodeMirror-ruler {
	border-left: 1px solid #ccc;
	position: absolute;
}

/* DEFAULT THEME */

.cm-s-default .cm-header {color: blue;}
.cm-s-default .cm-quote {color: #090;}
.cm-negative {color: #d44;}
.cm-positive {color: #292;}
.cm-header, .cm-strong {font-weight: normal;}
.cm-em {font-style: italic;}
.cm-link {text-decoration: underline;}
.cm-strikethrough {text-decoration: line-through;}

.cm-s-default .cm-keyword {color: #708;}
.cm-s-default .cm-atom {color: #219;}
.cm-s-default .cm-number {color: #164;}
.cm-s-default .cm-def {color: #00f;}
.cm-s-default .cm-variable,
.cm-s-default .cm-punctuation,
.cm-s-default .cm-property,
.cm-s-default .cm-operator {color: #FF8B00}
.cm-s-default .cm-variable-2 {color: #05a;}
.cm-s-default .cm-variable-3 {color: #085;}
.cm-s-default .cm-comment {color: #a50;}
.cm-s-default .cm-string {color: #a11;}
.cm-s-default .cm-string-2 {color: #f50;}
.cm-s-default .cm-meta {color: #555;}
.cm-s-default .cm-qualifier {color: #555;}
.cm-s-default .cm-builtin {color: #30a;}
.cm-s-default .cm-bracket {color: #997;}
.cm-s-default .cm-tag {color: #170;}
.cm-s-default .cm-attribute {color: #00c;}
.cm-s-default .cm-hr {color: #999;}
.cm-s-default .cm-link {color: #00c;}

.cm-s-default .cm-error {color: #f00;}
.cm-invalidchar {color: #f00;}

.CodeMirror-composing { border-bottom: 2px solid; }

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {color: #0f0;}
div.CodeMirror span.CodeMirror-nonmatchingbracket {color: #f22;}
.CodeMirror-matchingtag { background: rgba(255, 150, 0, .3); }
.CodeMirror-activeline-background {background: #e8f2ff;}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
the editor. You probably shouldn't touch them. */

.CodeMirror {
	position: relative;
	overflow: hidden;
	background: white;
}

.CodeMirror-scroll {
	overflow: scroll !important; /* Things will break if this is overridden */
	/* 30px is the magic margin used to hide the element's real scrollbars */
	/* See overflow: hidden in .CodeMirror */
	margin-bottom: -30px; margin-right: -30px;
	padding-bottom: 30px;
	height: 100%;
	outline: none; /* Prevent dragging from highlighting the element */
	position: relative;
}
.CodeMirror-sizer {
	position: relative;
	border-right: 30px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
	 before actuall scrolling happens, thus preventing shaking and
	 flickering artifacts. */
	 .CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
	 	position: absolute;
	 	z-index: 6;
	 	display: none;
	 }
	 .CodeMirror-vscrollbar {
	 	right: 0; top: 0;
	 	overflow-x: hidden;
	 	overflow-y: scroll;
	 }
	 .CodeMirror-hscrollbar {
	 	bottom: 0; left: 0;
	 	overflow-y: hidden;
	 	overflow-x: scroll;
	 }
	 .CodeMirror-scrollbar-filler {
	 	right: 0; bottom: 0;
	 }
	 .CodeMirror-gutter-filler {
	 	left: 0; bottom: 0;
	 }

	 .CodeMirror-gutters {
	 	position: absolute; left: 0; top: 0;
	 	z-index: 3;
	 }
	 .CodeMirror-gutter {
	 	white-space: normal;
	 	height: 100%;
	 	display: inline-block;
	 	margin-bottom: -30px;
	 	/* Hack to make IE7 behave */
	 	*zoom:1;
	 	*display:inline;
	 }
	 .CodeMirror-gutter-wrapper {
	 	position: absolute;
	 	z-index: 4;
	 	background: none !important;
	 	border: none !important;
	 }
	 .CodeMirror-gutter-background {
	 	position: absolute;
	 	top: 0; bottom: 0;
	 	z-index: 4;
	 }
	 .CodeMirror-gutter-elt {
	 	position: absolute;
	 	cursor: default;
	 	z-index: 4;
	 }
	 .CodeMirror-gutter-wrapper {
	 	-webkit-user-select: none;
	 	-moz-user-select: none;
	 	user-select: none;
	 }


	 .CodeMirror-lines {
	 	cursor: text;
	 	min-height: 1px; /* prevents collapsing before first draw */
	 }
	 .CodeMirror pre {
	 	/* Reset some styles that the rest of the page might have set */
	 	-moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0;
	 	border-width: 0;
	 	background: transparent;
	 	font-family: inherit;
	 	font-size: inherit;
	 	margin: 0;
	 	white-space: pre;
	 	word-wrap: normal;
	 	line-height: inherit;
	 	color: inherit;
	 	z-index: 2;
	 	position: relative;
	 	overflow: visible;
	 	-webkit-tap-highlight-color: transparent;

	 }
	 .CodeMirror-wrap pre {
	 	word-wrap: break-word;
	 	white-space: pre-wrap;
	 	word-break: normal;
	 }

	 .CodeMirror-linebackground {
	 	position: absolute;
	 	left: 0; right: 0; top: 0; bottom: 0;
	 	z-index: 0;
	 }

	 .CodeMirror-linewidget {
	 	position: relative;
	 	z-index: 2;
	 	overflow: auto;
	 }

	 .CodeMirror-widget {}

	 .CodeMirror-code {
	 	outline: none;
	 }

	 /* Force content-box sizing for the elements where we expect it */
	 .CodeMirror-scroll,
	 .CodeMirror-sizer,
	 .CodeMirror-gutter,
	 .CodeMirror-gutters,
	 .CodeMirror-linenumber {
	 	-moz-box-sizing: content-box;
	 	box-sizing: content-box;
	 }

	 .CodeMirror-measure {
	 	position: absolute;
	 	width: 100%;
	 	height: 0;
	 	overflow: hidden;
	 	visibility: hidden;
	 }

	 .CodeMirror-cursor { position: absolute; }
	 .CodeMirror-measure pre { position: static; }

	 div.CodeMirror-cursors {
	 	visibility: hidden;
	 	position: relative;
	 	z-index: 3;
	 }
	 div.CodeMirror-dragcursors {
	 	visibility: visible;
	 }

	 .CodeMirror-focused div.CodeMirror-cursors {
	 	visibility: visible;
	 }

	 .CodeMirror-selected { background: #d9d9d9; }
	 .CodeMirror-focused .CodeMirror-selected { background: #d7d4f0; }
	 .CodeMirror-crosshair { cursor: crosshair; }
	 .CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection { background: #d7d4f0; }
	 .CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection { background: #d7d4f0; }

	 .cm-searching {
	 	background: #ffa;
	 	background: rgba(255, 255, 0, .4);
	 }

	 /* IE7 hack to prevent it from returning funny offsetTops on the spans */
	 .CodeMirror span { *vertical-align: text-bottom; }

	 /* Used to force a border model for a node */
	 .cm-force-border { padding-right: .1px; }

	 @media print {
	 	/* Hide the cursor when printing */
	 	.CodeMirror div.CodeMirror-cursors {
	 		visibility: hidden;
	 	}
	 }

	 /* See issue #2901 */
	 .cm-tab-wrap-hack:after { content: ''; }

	 /* Help users use markselection to safely style text background */
	 span.CodeMirror-selectedtext { background: none; }




































/*

		Name:       material
		Author:     Michael Kaminsky (http://github.com/mkaminsky11)

		Original material color scheme by Mattia Astorino (https://github.com/equinusocio/material-theme)

		*/

		.cm-s-material {
			background-color: #18354a;
			color: white;
		}
		.cm-s-material .CodeMirror-gutters {
			background: #112b3e;
			color: rgb(83,127,126);
			border: none;
		}
		.cm-s-material .CodeMirror-guttermarker, .cm-s-material .CodeMirror-guttermarker-subtle, .cm-s-material .CodeMirror-linenumber { color: rgb(83,127,126); }
		.cm-s-material .CodeMirror-cursor { border-left: 1px solid #f8f8f0; }
		.cm-s-material div.CodeMirror-selected { background: rgba(255, 255, 255, 0.15); }
		.cm-s-material.CodeMirror-focused div.CodeMirror-selected { background: rgba(255, 255, 255, 0.10); }
		.cm-s-material .CodeMirror-line::selection, .cm-s-material .CodeMirror-line > span::selection, .cm-s-material .CodeMirror-line > span > span::selection { background: rgba(255, 255, 255, 0.10); }
		.cm-s-material .CodeMirror-line::-moz-selection, .cm-s-material .CodeMirror-line > span::-moz-selection, .cm-s-material .CodeMirror-line > span > span::-moz-selection { background: rgba(255, 255, 255, 0.10); }

		.cm-s-material .CodeMirror-activeline-background { background: rgba(0, 0, 0, 0); }
		.cm-s-material .cm-keyword {
			color: #ffaef4;
			background: rgba(255, 0, 218, 0.2);
		}
		.cm-s-material .cm-operator { color: #FF8B00; }
		.cm-s-material .cm-variable-2 { color: #52f4ff; }
		.cm-s-material .cm-variable-3 { color: #82B1FF; }
		.cm-s-material .cm-builtin { color: #DECB6B; }
		.cm-s-material .cm-atom { color: #F77669; }
		.cm-s-material .cm-number { color: rgba(248, 107, 255, 0.87); }
		.cm-s-material .cm-def { color: rgba(233, 237, 237, 1); }
		.cm-s-material .cm-string { color: #2edb00; }
		.cm-s-material .cm-string-2 { color: #80CBC4; }
		.cm-s-material .cm-comment {
			color: #b5fbb2;
			background: rgba(0,0,0,.38);
			font-weight: 300;
			padding: 2px 5px;
			text-shadow: 1px 1px 0 rgba(0,0,0,.52);
			border-radius: 3px;
			font-style: italic !important;
			font-family: $codeFontItalic !important;
		}
		.cm-s-material .cm-variable { color: #82B1FF; }
		.cm-s-material .cm-meta { color: #80CBC4; }
		.cm-s-material .cm-attribute { color: #ffc700;
			font-style: italic !important;
			font-family: $codeFontItalic !important;
		}
		.cm-s-material .cm-property { color: #FFF15F; }
		.cm-s-material .cm-qualifier { color: #FF9D00; }
		.cm-s-material .cm-variable-3 { color: #DECB6B; }
		.cm-s-material .cm-tag { color: #ff608b; }
		.cm-s-material .cm-error {
			color: rgba(255, 255, 255, 1.0);
			background-color: #EC5F67;
		}
		.cm-s-material .CodeMirror-matchingbracket {
			color: #050505 !important;
			background: rgba(255, 223, 6, 0.89);
			font-weight: 400;
			box-shadow: 2px 2px 9px rgba(0,0,0,0.39);
		}







// Custom Livee CSS

.CodeMirror-dialog {
	position: absolute;
	left: 0; right: 0;
	background: inherit;
	z-index: 15;
	padding: .1em .8em;
	overflow: hidden;
	color: inherit;
}

.CodeMirror-dialog-top {
	border-bottom: 1px solid #eee;
	top: 0;
}

.CodeMirror-dialog-bottom {
	border-top: 1px solid #eee;
	bottom: 0;
}



.CodeMirror-dialog input {
	border: none;
	outline: none;
	background: transparent;
	width: 20em;
	color: inherit;
}




.CodeMirror-dialog button {
	font-size: 70%;
}


.CodeMirror-activeline {
	background: rgba(0, 0, 0, 0.19);
}


.CodeMirror * {
	/* Set height, width, borders, and global font properties here */
	font-family: $codeFont !important;
	font-size: $codeFontSize !important;
	font-weight: 400;
}