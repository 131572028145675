.calendar {
	/* The main calendar widget.  DIV containing a table. */

	position: relative;
	display: none;
	font-size: 16px !important;
	color: #000000;
	cursor: default;
	background: #D4D0C8;
	font-family: $font;
	z-index: 10000;
	box-shadow: -3px -2px 9px 4px rgba(0, 0, 0, 0.10980392156862745) !important;
	border: 2px solid $color-main !important;
	border-radius: 4px !important;
	overflow: hidden !important;
	* {
		font-size: 16px !important;
		transition: all $uitime;
	}
	table {
		border: none !important;
		color: #000000;
		cursor: default;
		background: #FFFFFF !important;
		font-family: $font;
	}

	/* Header part -- contains navigation buttons and day names. */

	.button {
		/* "<<", "<", ">", ">>" buttons have this class */
		text-align: center;
		padding: 1px;
		border: none !important;
	}

	.nav {
		background: transparent url(menuarrow.gif) no-repeat 100% 100%;
	}

	thead .title {
		/* This holds the current "month, year" */
		font-weight: bold;
		padding: 1px;
		border: none !important;
		background: $color-main !important;
		color: #FFFFFF;
		text-align: center;
	}

	thead .headrow {
		/* Row <TR> containing navigation buttons */
	}

	thead .daynames {
		/* Row <TR> containing the day names */
	}

	thead .name {
		/* Cells <TD> containing the day names */
		border: none !important;
		padding: 2px;
		text-align: center;
		background: #F4F0E8;
		font-weight: bold !important;
		color: #9C9C9C !important;
	}

	thead .weekend {
		/* How a weekend day name shows in header */
		color: #929292 !important;
	}

	.hilite {
		/* How do the buttons in header appear when hover */
		border: none !important;
		padding: 0;
		background-color: #E4E0D8;
	}

	thead .active {
		/* Active (pressed) buttons in header */
		padding: 2px 0 0 2px;
		border: none !important;
		background-color: #C4C0B8;
	}

	/* The body part -- contains all the days in month. */

	tbody .day {
		/* Cells <TD> containing month days dates */
		width: 2em;
		text-align: right;
		padding: 2px 4px 2px 2px;
	}
	tbody .day.othermonth {
		font-size: 80%;
		color: #AAAAAA;
	}
	tbody .day.othermonth.oweekend {
		color: #FFAAAA;
	}

	table .wn {
		padding: 2px 3px 2px 2px;
		border: none !important;
		background: #F4F0E8;
	}

	tbody .rowhilite td {
		background: #E4E0D8;
	}

	tbody .rowhilite td.wn {
		background: #D4D0C8;
	}

	tbody td.hilite {
		/* Hovered cells <TD> */
		border: none !important;
		padding: 2px 4px 2px 2px !important;
		background: lighten($color-main, 10%) !important;
		color: white !important;
		transition: all $uitime;
	}

	tbody td.active {
		/* Active (pressed) cells <TD> */
		border: none !important;
	}

	tbody td.selected {
		/* Cell showing selected date */
		font-weight: bold;
		border: none !important;
		padding: 2px 4px 2px 2px !important;
		background: $color-main !important;
		color: white !important;
	}

	tbody td.weekend {
		/* Cells showing weekend days */
		color: #929292 !important;
	}

	tbody td.today {
		/* Cell showing today date */
		font-weight: bold;
		color: #0000FF;
	}

	tbody .disabled {
		color: #999999;
	}

	tbody .emptycell {
		/* Empty cells (the best is to hide them) */
		visibility: hidden;
	}

	tbody .emptyrow {
		/* Empty row (some months need less than 6 rows) */
		display: none;
	}

	/* The footer part -- status bar and "Close" button */

	tfoot .footrow {
		/* The <TR> in footer (only one right now) */
	}

	tfoot .ttip {
		/* Tooltip (status bar) cell <TD> */
		background: #F4F0E8;
		padding: 1px;
		border: none !important;
		background: $color-main !important;
		color: #FFFFFF;
		text-align: center;
	}

	tfoot .hilite {
		/* Hover style for buttons in footer */
		border: none !important;
		padding: 1px;
		background: #E4E0D8;
	}

	tfoot .active {
		/* Active (pressed) style for buttons in footer */
		padding: 2px 0 0 2px;
		border: none !important;
	}

	/* Combo boxes (menus that display months/years for direct selection) */

	.combo {
		position: absolute;
		display: none;
		width: 4em;
		top: 0;
		left: 0;
		cursor: default;
		border: none !important;
		background: #E4E0D8;
		font-size: 90%;
		padding: 1px;
		z-index: 100;
	}

	.combo .label, .combo .label-IEfix {
		text-align: center;
		padding: 1px;
	}

	.combo .label-IEfix {
		width: 4em;
	}

	.combo .active {
		background: #C4C0B8;
		padding: 0;
		border: none !important;
	}

	.combo .hilite {
		background: #004488;
		color: #FFEEAA;
	}

	td.time {
		border: none !important;
		padding: 1px 0;
		text-align: center;
		background-color: #F4F0E8;
		height: 37px;
	}

	td.time .hour, td.time .minute, td.time .ampm {
		padding: 0 3px 0 4px;
		border: 1px solid #888899;
		font-weight: bold;
		background-color: #FFFFFF;
	}

	td.time .ampm {
		text-align: center;
	}

	td.time .colon {
		padding: 0 2px 0 3px;
		font-weight: bold;
	}

	td.time span.hilite {
		border: none !important;
		background-color: #776666;
		color: #FFFFFF;
	}

	td.time span.active {
		border: none !important;
		background-color: #000000;
		color: #00FF00;
	}
} // E .CALENDAR
