/* Jeux de pages */

#layout_list {
	table fieldset table tr {
		border-bottom: 1px solid gainsboro;

		td:nth-child(3) {
			background-color: transparent !important;
			a {
				color: #4CAF50 !important;
				font-size: 16px;
				width: 100%;
				text-align: center;
				display: inline-block;
				background-color: transparent !important;
				&:hover, &:active {
					background-color: transparent !important;
				}
			}
		}

		td:nth-child(4) {
			background-color: transparent !important;
			a {
				color: #E91E63 !important;
				font-size: 16px;
				width: 100%;
				text-align: center;
				display: inline-block;
				&:hover, &:active {
					background-color: transparent !important;
				}
			}
		}
		&:hover {
			background: rgba(255, 236, 68, 0.6) !important;
		}
		&:active {
			background: rgba(255, 236, 68, 0.8) !important;
		}
	}
}

table.layouts_list_table {
	width: 100%;
	tr {
		&:hover {
			background: $selection_color !important;
		}
		&:nth-child(even) {
			background: #E6E6E6;
		}
	}
	a.layout_link {
		color: rgba(0, 0, 0, 0.62);
		font-size: 16px !important;
		font-weight: bold !important;
	}

	a.layout_raw_link, a.layout_qrcode_link {
		opacity: 0;
	}

	a {
		font-weight: 400;
		color: #333333;
		font-size: 12px;
		transition: all $uitime;
	}

	a[rel="#qrcode"] {
		color: grey !important;
	}
}

table.layouts_list_table tr {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

table.layouts_list_table tr:hover + a {
	color: red !important;
}
