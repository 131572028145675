// B Intégration graphique
#session_code {
	textarea.user_html, textarea {
		font-family: $codeFont !important;
		font-size: $codeFontSize !important;
		font-weight: 400 !important;
		background-color: #0B1B25 !important;
		color: #D9FF6E !important;
		width: 98%;
		margin: 1% 0;
		border-radius: 4px;
		border: 2px solid #C3C3C3;
		padding: 1%;
		box-sizing: border-box;
		line-height: 1.5em;
		min-height: 600px;
	}
	span.selected_template strong {
		color: #E3FFBA !important;
		font-size: 14px;
		background: #339472;
		padding: 1px 5px;
		border-radius: 4px;
		box-sizing: border-box;
	}
	#graph_integration_status {
		position: fixed;
		bottom: 59px;
		padding: 0;
		right: 20px;
		width: 139px;
		text-align: right;
		color: hsla(0, 0%, 100%, 0.72);
		height: auto;
		border-radius: 0;
		z-index: 2;
		background: transparent;
		p, span {
			display: inline-block !important;
			text-align: left;
			font-size: 12px !important;
		}
		p:last-child {
			display: none !important;
		}
	}
}

// E Intégration graphique
// B INFOS SESSIONS
#session_list {
	// Session button
	td.row.sessionlist_description a:first-child {
		width: 100%;
		display: block;
		padding: 11px;
		font-size: 16px !important;
		color: #232323;
		box-sizing: border-box;
		&:hover {
			background: transparent !important;
		}
		&:active {
			background: $color-main !important;
			color: white !important;
		}
	} // Session List size
	table#chatSessionList {
		* {
			font-size: 14px;
		}
		.column_title_forcelogout, .column_title_delete, .column_title_edit, .column_title_close, .column_title_start, .column_title_status, td.row.column_title_schedule, td.row.column_title_session, .column_title_status, .column_title_activated, .column_title_description {
			width: 70px !important;
			font-size: 12px !important;
			padding: 5px;
			text-align: left !important;
			color: #9C9C9C;
			border-bottom: 2px solid $color-main !important;
		}
		tr:hover {
			background: $selection_color;
		}
		.sessionlist_description {
			text-align: left !important;
		}
		td.row.column_title_session {
			min-width: 67px;
		}
		td.row.sessionlist_status {
			max-width: 96px;
			padding: 0 3px;
		}
		td.row.sessionlist_description {
			min-width: 50%;
		}
		td.row.column_title_schedule {
			width: 210px !important;
			font-size: 12px !important;
		}
		td.row.sessionlist_session {
			font-size: 16px !important;
			font-weight: bold;
			color: darken($color-main, 10%);
		}
	} // Session List buttons
	a.button-editsession, a.button-deletesession, a.button-startsession, a.sessionlist_activate, a.button-closesession, .sessionlist_activated a, .button-force {
		@extend .noselect;
		background: rgba(204, 232, 255, 0.4);
		box-sizing: border-box;
		display: block;
		text-align: center;
		margin: 0 auto;
		padding: 10px 4px !important;
		color: #2196F3;
		&:hover {
			background: #03A9F4;
			color: white;
		}
		&:active {
			background: #028DCC;
			color: white;
		}
	}
	a.button-deletesession {
		background: rgba(244, 67, 54, 0.07);
		color: #F44336;
		&:hover {
			background: #F44336;
			color: white;
		}
		&:active {
			background: #C5291D;
			color: white;
		}
	}
	a.button-startsession {
		background: rgba(139, 195, 74, 0.19);
		color: #2C7919;
		&:hover {
			background: rgba(139, 195, 74, 0.99);
			color: white;
		}
		&:active {
			background: darken(rgba(139, 195, 74, 1), 10%);
			color: white;
		}
	}
	a.button-closesession {
		background: rgba(0, 0, 0, 0.13);
		color: #616161;
		&:hover {
			background: #616161;
			color: white;
		}
		&:active {
			background: darken(#616161, 10%);
			color: white;
		}
	}
	.sessionlist_session {
		text-align: center !important;
	}
}

// B INFOS SESSIONS
// SUPER ADMIN LIST
tr.customerListClosingTd {
	border-bottom: 2px solid rgb(206, 206, 206);
	padding-bottom: 30px !important;
}

// QUESTION A L'AVANCE
#session_questions {
	.session_question_row {
		&:nth-child(odd) {
			background: #E6E6E6;
		}
	}
	a.session_question_btn {
		min-width: 23px !important;
		width: 23px !important;
	}
	.starbox {
		float: right !important;
	}
	fieldset p table tbody tr td:nth-child(3) {
		text-align: right;
	}
	.session_question_buttons {
		text-align: right;
		color: transparent;
	}
	.session_question_empty, .session_question_delete, .session_question_delete2, .session_question_edit, .session_question_edit2, .session_question_arrowup, .session_question_arrowdown {
		font-size: 12px !important;
		padding: 0 8px !important;
		i {
			color: white !important;
			position: relative;
			top: 6px;
		}
	}
	.session_question_edit, .session_question_edit2 {
		background: darken($editcolor, 20%) !important;
	}
	.session_question_empty, .session_question_delete, .session_question_delete2 {
		background: darken($removecolor, 20%) !important;
	}
	td.row.session_question_title {
		padding: 3px 5px;
		i {
			color: #383838;
			display: inline !important;
			position: relative;
			top: 7px;
		}
	}
} // E QUESTION A L'AVANCE
