#header {
	a[href="logout.php"] {
		background: #E91E63;
		color: white;
		position: fixed;
		top: 0;
		right: 34px;
		padding: 5px;
		width: 34px !important;
		text-align: center;
		font-size: 11px;
		text-transform: uppercase;
		box-sizing: border-box;
		font-weight: 900;
		z-index: 100;
		height: 33px;
		overflow: hidden;
	}
	a[href="customer_logout.php"] {
		background: #03A9F4;
		color: white;
		position: fixed;
		top: 0 !important;
		right: 0 !important;
		padding: 5px;
		width: 34px !important;
		text-align: center;
		font-size: 11px;
		text-transform: uppercase;
		box-sizing: border-box;
		font-weight: 900;
		z-index: 100;
		height: 33px;
		overflow: hidden;
	}
}

.topMenus {
	background: transparent !important;
	position: absolute;
	top: 0;
	width: 100px;
	height: 33px;
	color: white;
	border: none;
	left: 144px;
	cursor: pointer;
	font-size: 12px;
	font-weight: 700;
	box-sizing: border-box;
	        border-radius: 0;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	   -moz-appearance: none;
	text-indent: 0.01px;
	text-overflow: "";
	text-align: left;
}

select[name="LanguageID"] {
	@extend .topMenus;
	option {
		color: black !important;
	}
}

select[name^="UserAccountType"] {
	@extend .topMenus;
	left: 253px;
	width: 124px;
	option {
		color: black !important;
	}
}

#header_logo {
	height: 26px;
	width: 104px;
	position: absolute;
	top: 0 !important;
	left: 0 !important;
	padding: 4px 10px 4px 15px;
	transition: all $uitime;
	z-index: 9;
	a {
		height: 33px !important;
		display: block;
		top: 0;
		position: absolute;
		left: 0 !important;
		box-sizing: border-box;
		padding: 3px 2px 0 8px;
		&:hover {
			background: rgba(0, 0, 0, 0.2) !important;
		}
		&:active {
			background: rgba(0, 0, 0, 0.4) !important;
		}
		img {
			height: 28px;
			z-index: 10;
		}
	}
}

form[action="content_search.php"] {
	position: absolute;
	top: 0;
	left: 386px;
	z-index: 2;
	input[name="ContentSearchQuery"] {
		background-color: darken($color-main, 10%);
		padding: 4px 4px !important;
		border: 0;
		border-radius: 2px;
		text-align: left;
		width: 94px;
		font-size: 12px;
		font-weight: bold;
		color: white;
		font-family: $font;
		&::-webkit-input-placeholder {
			/* WebKit, Blink, Edge */
			color: rgba(255, 255, 255, 0.63);
		}
		&:-moz-placeholder {
			/* Mozilla Firefox 4 to 18 */
			color: rgba(255, 255, 255, 0.63);
			opacity: 1;
		}
		&::-moz-placeholder {
			/* Mozilla Firefox 19+ */
			color: rgba(255, 255, 255, 0.63);
			opacity: 1;
		}
		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: rgba(255, 255, 255, 0.63);
		}
		&:hover {
			background-color: darken($color-main, 12%);
		}
		&:focus {
			background-color: darken($color-main, 15%);
		}
	}
	select {
		@extend .topMenus;
		position: relative !important;
		top: initial !important;
		left: initial !important;
	}
	input[type="submit"] {
		display: none !important;
	}
}

/* header */

#header {
	height: 33px !important;
	width: 100%;
	background: $color-main;
	position: relative;
	select[name="ContentSearchChatSessionID"] {
		max-width: 200px !important;
	}
	div#conInfo {
		img {
			display: none !important;
		}
		display: inline-block;
		* {
			display: inline-block;
		}
		br {
			display: none !important;
		}
	}
	span.user_login {
		position: fixed;
		top: 0;
		right: 68px;
		font-size: 14px;
		display: block;
		text-align: left;
		line-height: 12px;
		text-align: center;
		width: auto;
		color: #FFFFFF !important;
		height: 33px;
		background: $color-main;
		box-sizing: border-box;
		font-size: 12px;
		font-weight: 600;
		z-index: 9;
		border-radius: 0 0 0 6px;
		padding: 3px 10px 5px;
		.accountName, .sessionName {
			display: block !important;
			text-align: left;
			line-height: 12px;
		}
		.accountName {
			font-weight: 700 !important;
			margin-bottom: 3px;
			color: white !important;
		}
	}
	a[href="javascript:PopupLink('whats_new.php?popup=1');void(0);"] {
		display: none !important;
		position: absolute;
		top: 5px;
		left: 388px;
		font-size: 12px;
		color: #FFFFFF;
		font-weight: 700;
		border-radius: 2px;
		background: rgba(0, 0, 0, 0.17);
		padding: 4px;
		box-sizing: border-box;
		&:hover {
			background: rgba(0, 0, 0, 0.27) !important;
			color: #FFFFFF !important;
		}
		&:active {
			background: rgba(0, 0, 0, 0.47) !important;
			color: #FFFFFF !important;
		}
	}
}

/* level1 */

.menu_level1 {
	background: darken($color-main, 10%);
	padding: 0;
	i.material-icons {
		line-height: 0;
		display: inline-block;
		top: 6px;
		position: relative;
		left: -4px;
	}
}

.menu_level1 tr td {
	padding: 0;
}

.item_level1 {
	border: none !important;
	width: $menu_lvl1_width;
	display: inline-block;
	color: white !important;
	&:hover, &:active {
		color: white !important;
	}
}

.item_level1_active {
	background: none;
	border: none !important;
	width: $menu_lvl1_width;
	display: inline-block;
}

.menu_level1 tr * {
	display: inline-block;
}

.menu_level1 tr .checklists_list {
	display: none;
	background: #FFFFFF !important;
	border-radius: 4px !important;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25) !important;
	.inside li {
		padding-top: 5px;
		float: left;
		display: block;
		width: 100%;
		a {
			border: none !important;
			width: 100%;
		}
	}
}

.menu_level1 tr {
	padding: 0;
}

.item_level1_active a, .item_level1 a, .checklists a {
	font-weight: 400;
	color: rgba(255, 255, 255, 0.9);
	text-decoration: none;
	font-size: 14px !important;
	width: $menu_lvl1_width;
	padding: 7px 2px;
	text-align: center;
	display: inline-block;
	transition: all $uitime;
	white-space: nowrap;
	box-sizing: border-box;
	border-right: 1px solid #404040;
	&:hover {
		font-weight: 400;
		color: #FFFFFF;
		text-decoration: none;
		background-color: rgba(0, 0, 0, 0.18);
	}
	&:active {
		background-color: rgba(0, 0, 0, 0.5);
	}
}

.item_level1_active a {
	background-color: rgba(0, 0, 0, 0.34);
}

tbody, tbody tr, .item_level1 td {
	width: 100%;
}

td.item_level1 img {
	display: none !important;
}

td.item_level1_active img {
	display: none !important;
}

td.item_level1 table, td.item_level1_active table {
	display: block;
	width: 100%;
}

/* level2 */

.menu_level2 {
	// background-color: #EDEDED;
	display: block !important;
	position: relative;
	float: left !important;
	table {
		height: inherit;
	}
}

.item_level2 {
	border: none;
	background: #EDEDED;
	padding: 0;
	border-right: 1px solid #A9A9A9;
	white-space: nowrap;
	display: block;
	float: left;
	i.material-icons {
		line-height: 0;
		display: inline-block;
		top: 6px;
		position: relative;
		left: -4px;
		max-width: 24px;
		min-width: 24px;
	}
	a, a:hover, a.selected, a.selected {
		font-weight: 400;
		color: #666666;
		font-size: 13px;
		text-decoration: none;
		height: 100%;
		display: inline-block;
		position: relative;
		line-height: 30px;
		padding: 0 9px;
		border-bottom: 1px solid darkgrey;
		transition: all $uitime;
	}
	a:hover {
		background-color: darken(rgb(228, 227, 227), 5%);
	}
	a.selected {
		background-color: white;
		color: $color-main !important;
		font-weight: 700 !important;
		border-bottom: 1px solid #A9A9A9 !important;
	}
	a.selected:hover {
		background-color: #FFFFFF;
	}
	a:active {
		background-color: darken(rgb(228, 227, 227), 15%);
	}
}

td.menu_level3 {
	font-size: 1em;
	color: #FFFFFF;
	background: #FFFFFF;
	i.material-icons {
		line-height: 0;
		display: inline-block;
		top: 6px;
		position: relative;
		left: -4px;
		width: 24px;
	}
	a.graphical_integration {
		background: rgba(244, 186, 3, 0.2);
	}
	a.tags {
		background: rgba(244, 3, 98, 0.2);
	}
	a.video {
		background: rgba(3, 169, 244, 0.2);
	}
	p {
		margin: 0;
	}
	p:first-child {
		display: none;
		position: absolute;
		top: -1px;
		right: 258px;
		font-size: 14px;
		color: #FFFFFF;
		width: 356px;
		box-sizing: border-box;
		padding: 7px;
		text-align: center;
		height: 46px;
		strong {
			font-size: 14px;
			font-weight: 400;
			color: #CFFF91;
		}
		span.accent {
			color: #CFFF91;
			font-weight: 700;
			font-size: 17px;
			background: #000000;
			padding: 0 7px;
			box-sizing: border-box;
			line-height: 12px;
		}
	}
	nobr {
		margin: 0 !important;
		display: inline-block;
		box-sizing: border-box;
		padding: 0 !important;
	}
	nobr a {
		display: inline-block;
		padding: 6px 7px;
		margin: 6px 5px 1px 0;
		font-size: 13px;
		border-radius: 3px;
		color: $color-main;
		box-sizing: border-box;
		transition: all $uitime;
		font-weight: 400;
		top: 1px;
		position: relative;
		background: #FFFFFF;
		border: 1px solid rgba(0, 0, 0, 0.08);
		img {
			display: none;
		}
		&:hover {
			// color: #FFFFFF;
			background: rgba(146, 146, 146, 0.1);
		}
		&:active {
			// color: #FFFFFF;
			background: rgba(146, 146, 146, 0.2);
		}
	}
}

// E Couleurs boutons IG
