#ChecklistHelp {
	display: none !important;
}


td#helpPanel {
	display: none !important;
	width: 0% !important;
	padding: 0 !important;
	visibility: hidden;
	position: fixed !important;
	top: -10000px;
	right: 9999px;
	opacity: 0;
	z-index: -1;
}