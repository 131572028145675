// Global Wrappers
fieldset {
	border: 4px solid hsl(0, 0%, 88%); // margin: 30px 4px 60px 4px !important;
	margin: 20px 4px 30px!important;
	padding: 15px 0;
	border-radius: 2px;
	background-color: hsl(0, 0%, 98%); // box-shadow: 4px 1px 11px 2px rgba(0, 0, 0, 0.09);
}

// Graphical integration
fieldset.collapsed {
	border: none;
	background-color: transparent;
	cursor: pointer;
	margin: 0!important;
	padding: 0!important;
	box-shadow: none !important;
	border-top: none !important;
	height: 33px;
}

fieldset.collapsible {
	margin: 0 !important;
	border: none !important;
}

.legend {
	font-weight: 500;
	color: #3C3C3C;
	font-size: 18px;
	padding: 6px 0 6px 8px;
	margin: 0 auto;
	width: 100%;
	text-align: left;
	position: relative;
	left: -4px;
	display: block;
	background: rgba(212, 212, 212, 0.34);
}

legend {
	font-weight: 500;
	color: #3C3C3C;
	font-size: 18px;
	padding: 6px 0 6px 8px;
	border-radius: 1px;
	background: #D4D4D4;
	margin: 0 auto;
	width: 100%;
	text-align: left;
	position: relative;
	left: -4px;
	.collapsed {
		transition: all 0.3s;
		color: #4A4A4A !important;
		background: transparent !important;
		span {
			color: $color-main !important;
		}
		&:hover {
			background: #D8D8D8 !important;
		}
	}
}

legend.collapsed {
	transition: all 0.3s;
	color: #4A4A4A !important;
	background: transparent !important;
	span {
		color: $color-main !important;
	}
	&:hover {
		background: #D8D8D8 !important;
	}
}

legend.collapsible {
	transition: all 0.3s;
	cursor: pointer;
	span {
		color: $color-main;
		font-size: 14px;
		display: inline-block;
		padding-right: 5px;
	}
}

form#SetListContainer select {
	width: 500px;
	margin: 13px;
}

#session_chatquestions {
	.deleteQuestion_title, .deleteQuestion_cell {
		@extend .displayhide;
	}
	fieldset#chatquestions_listing p {
		padding: 0 15px;
	}
}
