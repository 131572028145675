

.ae-ui {
	button {
		background: initial !important;
	}
}


span.warningEditor {
	padding: 5px;
	box-sizing: border-box;
	color: #7d2168;
	width: 67%;
	display: inline-block;
	position: relative;
	top: 9px;
}

.focused {
	border-color: red !important;
}

textarea#alloy {
	width: 100% !important;
	background: #122b3c;
	color: white;
	font-family: $codeFont, monospace !important;
	font-size: $codeFontSize;
	min-height: 500px;
}


.ae-ui .ae-dropdown, .ae-ui [class^=ae-dropdown-] {
	background-color: $color-main;
}

.cke_textarea_inline {
	min-height: 500px;
	background: white !important;
	border: 2px solid #cfcfcf !important;
	border-radius: 4px !important;
	padding: 20px !important;
	h1, h2, h3, h4, h5, h6 {
		margin-bottom: .5rem;
		text-align: initial;
		color: initial;
	}
	h1 {
		font-size: 30px !important;
	}

	h2 {
		font-size:  24px !important;
	}

	h3 {
		font-size:  22px !important;
	}

	h4 {
		font-size:  20px !important;
	}

	h5 {
		font-size: 18px !important;
	}

	h6 {
		font-size: 16px !important;
	}


}





.ae-button-add {
	border-radius: 50% !important;
}

.ae-container-edit-link-target.ae-container-dropdown.ae-container-dropdown-medium.ae-has-dropdown {
	* {
		color: $color-main;
	}
	&:hover {
		* {
			color: white !important;
		}
	}
}



a.add-tag-btn {
	margin-right: 12px;
	display: inline-block !important;
	// position: absolute;
	// top: 138px;
	// right: 46px;
	background: $new_add_button_color !important;
	color: #fff!important;

	&:hover {
		background: darken($new_add_button_color, 10%) !important;
	}

	&:active {
		background: darken($new_add_button_color, 15%) !important;
	}

	i.material-icons {
		padding-right: 13px;
		font-size: 20px;
	}
}





img#TagBodyImage {
	@extend .transparentBg;
	width: 100%;
	border: 2px solid #e0e0e0;
	box-sizing: border-box;
	border: 2px solid #e0e0e0;
	padding: 0;
}
