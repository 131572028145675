.modal {
	background: #FFFFFF;
	padding: 10px;
	border-radius: 3px;
	position: fixed !important;
	top: 10% !important;
	left: 50% !important;
	box-sizing: border-box;
	width: 600px;
	margin-left: -300px !important;
	box-shadow: 4px 6px 13px 1px rgba(0, 0, 0, 0.39);
	display: none;
}

h2 {
	color: $color-main;
	font-size: 18px !important;
	margin: 0 !important;
	text-align: center;
}

// Not sure :

.poll_overlay, .form_overlay {
	top: 10px !important;
	box-shadow: none !important;
}
